import React from 'react'
import "../css/index.css"
import { Button, Divider, Space, Tooltip } from 'antd';


const EmpSalaryDepart = () => {
    return (
        <>
                    <div className="col-lg-6 emp_salary_depart_table mt-3">
                        <h5 className='emp_salary_depart_table_head'>Employee Salary by department</h5>
                        <div className='overFlow'>
                        <table className=''>
                            <tr>
                                <td>Sales</td>
                                
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Sale</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Marketing</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Marketing</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>IT</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : IT</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Human Resources</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Human Resources</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Finance</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Finance</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Customer Support</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Customer Support</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Administration</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Administration</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td>Accounting</td>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                                <Tooltip 
                                    title={<>Salary : 26.37k <br /> Month Year : Oct 2023 <br /> Department : Accounting</>} color={'white'} key={"5"} 
                                    overlayInnerStyle={{ color: "#000" }}
                                >
                                    <td>26.37k</td>
                                </Tooltip>
                            </tr>
                            <tr>
                                <td></td>
                                <td className='clendar'><span>Oct 2023</span></td>
                                <td className='clendar'><span>Nov 2023</span></td>
                                <td className='clendar'><span>Dec 2023</span></td>
                                <td className='clendar'><span>Jan 2024</span></td>
                                <td className='clendar'><span>Fed 2024</span></td>
                                <td className='clendar'><span>Mar 2024</span></td>
                            </tr>
                        </table>
                        </div>
                    </div>
        </>
    )
}

export default EmpSalaryDepart